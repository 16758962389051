import * as React from 'react';
import Grid from '@mui/material/Grid2';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import StatCard from '../submodules/StatCard';
import HighlightedCard from '../submodules/HighlightedCard';
import { Container, Divider } from '@mui/material';

const data = [
  {
    title: 'Estimates Created',
    value: 'Over 200M',
    interval: 'Since Implemtation',
    trend: 'neutral',
    data: [
      200, 24, 220, 260, 240, 380, 100, 240, 280, 240, 300, 340, 320, 360, 340, 380,
      360, 400, 380, 420, 400, 640, 340, 460, 440, 480, 460, 600, 880, 920,
    ],
    percentage: null,
  },
  {
    title: 'Conversions',
    value: 'Sustainable Growth',
    interval: 'Month Over Month',
    trend: 'up',
    data: [
      780, 800, 760, 380, 740, 660, 620, 840, 500, 520, 480, 400, 360, 300, 220,
      1640, 1250, 970, 1130, 1050, 900, 1720, 1080, 1900, 1450, 920, 1820, 1840, 1600, 1820,
    ],
    percentage: "30%",
  },
  {
    title: 'Finance Sales',
    value: 'Over 230%',
    interval: 'Annually',
    trend: 'up',
    data: [
      500, 400, 510, 530, 520, 600, 530, 520, 510, 730, 520, 510, 530, 620, 510, 530,
      520, 410, 530, 520, 1610, 1530, 1520, 1610, 1530, 1420, 1510, 1130, 1120, 1510,
    ],
    percentage: "230%"
  },
];

const Statistics = (props) => {
  return (
    <Box sx={{ width: '100%', maxWidth: { sm: '100%', md: '1700px' } }}>
      {/* cards */}
      <Divider sx={{my: 5,}}/>
      <Box sx={{display: "flex", width: "100%", alignItems: "center", justifyContent: "center",}}>
        <Box>
          <Typography sx={{lineHeight: .75,}} fontFamily="Bebas Neue" component="h2" variant="h2" textAlign="center" fontSize={props?.windowSize?.width < 768 ? 30 : 60 } fontWeight={600}>Proven Results</Typography>
          <Typography fontSize={20} textAlign="center" maxWidth={768} sx={{my:2,}}>Our intelligent sales solution has increased conversions up to 30%. We've been responsible for generating over 200M estimates for our clients and and grown finance sales over 230%.</Typography>
        </Box>
      </Box>
      <Box sx={{display: "flex", justifyContent: "center"}}>
        <Container maxWidth="xl">
          <Grid
            container
            spacing={2}
            columns={12}
            sx={{ mb: (theme) => theme.spacing(2) }}
          >
            {data.map((card, index, percentage) => (
              <Grid key={index} size={{ xs: 12, sm: 6, lg: 6 }}>
                <StatCard {...card} />
              </Grid>
            ))}
            <Grid size={{ xs: 12, sm: 6, lg: 6 }}>
              <HighlightedCard />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  );
}

export default Statistics