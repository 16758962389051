import { createTheme } from "@mui/material";

export const AppDefaultTheme = createTheme({
  palette: {
    type: 'light',
    primary: {
      light: "#16a3b6",
      main: '#163c51',
    },
    secondary: {
      main: '#91b621',
    },
    tertiary: {
      main: "#d13138",
      dark: "#b71c1c",
    }
  },
});
