import { Box, Button, Container, Typography } from '@mui/material';
import React from 'react';
import GraphicHero from '../../assets/png/graphic-hero.png'

const BannerVideo = (props) => {
  const headingSize = props?.windowSize?.width < 768 ? 60 : props?.windowSize?.width < 1260 ? 65 : 85;
  const paragraphSize = props?.windowSize?.width < 768 ? 16 : 18;

  return (
    <>
      {/* Video Section */}
      <Box
        sx={{
          position: 'relative',
          width: '100%',
          height: '100vh',  // Only the video section will cover the viewport height
          overflow: 'hidden'
        }}
      >
        {/* Video Background */}
        <Box
          component="video"
          className="background-video"
          autoPlay
          loop
          muted
          playsInline
          src="https://videos.pexels.com/video-files/7646697/7646697-uhd_2560_1440_25fps.mp4"
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            zIndex: 1,
          }}
          // Author Credit, Alena Darmel on Pexels: https://www.pexels.com/video/person-showing-the-house-floor-plan-7646445/
        />

        {/* Dark Overlay */}
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.75)',
            zIndex: 2,
          }}
        ></Box>

        {/* Text Layer */}
        <Box
          sx={{
            alignItems: 'flex-start',
            bottom: 0,
            display: 'flex',
            flex: 1,
            width: "100%",
            flexDirection: 'column',
            position: 'absolute',
            zIndex: 3,
          }}
        >
          <Container maxWidth="100%"
           sx={{ display: "flex", flexDirection: "row", gap: 2, alignItems: "flex-end", justifyContent: "space-between", width: "100%", maxWidth: "100%", mb: 5 }}>
            {/* Text Section */}
            <Box sx={{ flex: 2, maxWidth: 768, px:2, }}>
              <Typography
                color="white"
                component="h1"
                fontFamily="Bebas Neue"
                fontSize={headingSize}
                fontWeight={500}
                lineHeight={1}
                variant="h1"
              >
                A Collaborative Tech Stack Designed for Efficiency and Insight.
              </Typography>
              <Typography
                color="white"
                component="h2"
                fontFamily="Roboto"
                fontSize={paragraphSize}
              >
                Streamline your workflow with an integrated solution that combines a dynamic sales front-end, a centralized design application, and intelligent, location-specific business analytics. Empower your team with tools that enhance collaboration, boost productivity, and deliver actionable insights-all in one seamless platform.
              </Typography>
              <Button href="#contact-us" size="large" color="tertiary" variant="contained" sx={{ mt: 1, color: 'white' }}>
                Inquire Now
              </Button>
            </Box>

            {/* Graphic Section */}
            <Box sx={{ flex: 1, display: props?.windowSize?.width > 1116 ? "flex" : 'none', justifyContent: "center" }}>
              <Box component="img" src={GraphicHero} width={565} height={515} />
            </Box>
          </Container>
        </Box>
      </Box>
    </>
  );
};

export default BannerVideo;
