import React from 'react';
import { Box, Typography, Grid, Avatar, Card, CardContent, Rating } from '@mui/material';

// Example testimonials data
const testimonials = [
  {
    name: 'Neighborly',
    role: 'Representative',
    avatar: '/assets/svg/logo-neighborly.svg',
    rating: 5,
    feedback: 'Advantage+ has given us the edge that other systems don’t have. Our entire in-home sales process is now delivered from one sales app and links seamlessly with our other systems.',
  },
  {
    name: 'Lotus',
    role: 'Representative',
    avatar: '/assets/svg/logo-lotus.svg',
    rating: 5,
    feedback: 'We are delighted to be partnering with Loop, whose intuitive platform centralises our data, enables greater collaboration, and for us to measure the impact of actions our teams are taking on our KPIs. Loop is already driving improvements in performance within our network.',
  },
  {
    name: 'Volkswagen Group',
    role: 'Representative',
    avatar: '/assets/svg/logo-volkswagen.svg',
    rating: 5,
    feedback: 'Users comment on the simplicity of the Loop system and how easy and clear it is to find what they are looking for. This has contributed to a 10% improvement in efficiency and performance across the group.',
  },
];

const Testimonials = () => {
  return (
    <Box sx={{ py: 6, px: 3, backgroundColor: '#f9f9f9' }}>
      <Typography variant="h2" component="h2" align="center" fontFamily="Bebas Neue">
        What Our Clients Say
      </Typography>
      <Typography textAlign="center" fontSize={20} sx={{mb:4,}}>Listen to the folks that have actually implemented this service.</Typography>
      <Box sx={{display: "flex", flexWrap: "wrap", justifyContent: "space-around"}}>
        {testimonials.map((testimonial, index) => (
          <Box key={index} sx={{m: 1,}}>
            <Card sx={{ maxWidth: 345, margin: 'auto', textAlign: 'center', boxShadow: 3 }}>
              <CardContent>
                <Avatar
                  src={testimonial.avatar}
                  alt={testimonial.name}
                  sx={{ width: 80, height: 80, margin: 'auto', mb: 2 }}
                />
                <Typography variant="h6" gutterBottom>
                  {testimonial.name}
                </Typography>
                <Typography variant="body2" color="textSecondary" sx={{ mb: 2 }}>
                  {testimonial.role}
                </Typography>
                <Rating
                  name={`rating-${index}`}
                  value={testimonial.rating}
                  precision={0.5}
                  readOnly
                  sx={{ mb: 2 }}
                />
                <Typography variant="body1" sx={{ fontStyle: 'italic' }}>
                  "{testimonial.feedback}"
                </Typography>
              </CardContent>
            </Card>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default Testimonials;
