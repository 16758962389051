import React, { useState } from 'react';
import { Box, Button, CircularProgress, IconButton, Snackbar, TextField, Typography } from '@mui/material';
import { sendEmailWithSES } from '../../utils/SendEmailWithSES';
import CloseIcon from '@mui/icons-material/Close';
import { CheckEmail } from '../../utils/CheckEmail';
import { GoogleReCaptchaProvider, useGoogleReCaptcha } from 'react-google-recaptcha-v3';

const ContactForm = (props) => {
  const [emailAddress, setEmailAddress] = useState('');
  const [emailAddressError, setEmailAddressError] = useState(false);
  const [emailAddressHelperText, setEmailAddressHelperText] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [message, setMessage] = useState('');
  const [isSnackbarVisible, setIsSnackbarVisible] = useState(false);
  const [isEmailSending, setIsEmailSending] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const handleSnackbarOpen = () => {
    setIsSnackbarVisible(true);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setIsSnackbarVisible(false);
    setSnackbarMessage("");
  };

  const action = (
    <React.Fragment>
      <Button color="secondary" size="small" onClick={handleSnackbarClose}>
        CLOSE
      </Button>
      <IconButton size="small" aria-label="close" color="inherit" onClick={handleSnackbarClose}>
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleClick = async () => {
    setEmailAddressError(false);
    setEmailAddressHelperText("");
    setIsEmailSending(true);
  
    const token = await executeRecaptcha("contact_form");
  
    // Validate reCAPTCHA token
    if (!token) {
      console.error("reCAPTCHA verification failed");
      setIsEmailSending(false);
      setSnackbarMessage("reCAPTCHA verification failed")
      setIsSnackbarVisible(true);
      return;
    }
  
    // Check for spam-like content
    const spamKeywords = [
      "viagra", "penis", "cialis", "cock", "casino", "gambling", "dick", "asshole", "levitra", "medications",
      "adult", "xxx", "porn", "dating", "weight loss", "diet", "earn extra cash", "virus", "hacked",
      "malware", "online pharmacy", "crypto", "get rich quick", "meet singles", "meet hot girls", "meet hot guys",
      "spam", "human growth hormone", "join millions", "no credit check", "tits", "balls", "graphic design", "fee",
      "obligation", "offshore", "s 100%", "safeguard notice", "а", "б", "в", "г", "д", "е", "ё", "ж", "з", "и", "й",
      "к", "л", "м", "н", "о", "п", "р", "с", "т", "у", "ф", "х", "ц", "ч", "ш", "щ", "ъ", "ы", "ь", "э", "ю", "я",
      "А", "Б", "В", "Г", "Д", "Е", "Ё", "Ж", "З", "И", "Й", "К", "Л", "М", "Н", "О", "П", "Р", "С", "Т", "У", "Ф",
      "Х", "Ц", "Ч", "Ш", "Щ", "Ъ", "Ы", "Ь", "Э", "Ю", "Я"
    ];
    const containsSpam = spamKeywords.some(keyword => message.toLowerCase().includes(keyword.toLowerCase()));
  
    if (containsSpam) {
      setIsEmailSending(false);
      setSnackbarMessage("This email could not be sent. Please contact support.");
      setIsSnackbarVisible(true);
      return;
    }
  
    if (CheckEmail(emailAddress)) {
      const emailRecipient = "it@cadsoft.com";
      const emailSubject = "Lines x Personal Architect: Contact Form";
      const emailMessage =
        message +
        `\nThis email was sent by ${firstName} ${lastName} through the Personal Architect contact form on ${window.location} by ${emailAddress}`;
  
      sendEmailWithSES(emailRecipient, emailSubject, emailMessage)
        .then(() => {
          setEmailAddress("");
          setFirstName("");
          setLastName("");
          setMessage("");
          setSnackbarMessage("Email sent.");
          handleSnackbarOpen(true);
          setIsEmailSending(false);
        })
        .catch((error) => {
          console.error("Error sending email:", error);
          setSnackbarMessage("Error sending email.");
          setIsEmailSending(false);
        });
    } else {
      setEmailAddressError(true);
      setEmailAddressHelperText("Invalid email address.");
      setIsEmailSending(false);
    }
  };
  

  return (
    <Box>
      <Typography component="h2" fontWeight={600} variant="h3" sx={{p:2,}}>Contact Us</Typography>
      <Box>
        <Box sx={{ display: 'flex' }}>
          <Box sx={{ flex: 1, p: 0.5 }}>
            <TextField
              aria-label="First Name"
              fullWidth
              label="First Name"
              onChange={(e) => {
                setFirstName(e.target.value);
              }}
              placeholder="Your First Name"
              value={firstName}
              variant="outlined"
            />
          </Box>
          <Box sx={{ flex: 1, p: 0.5 }}>
            <TextField
              aria-label="Last Name"
              fullWidth
              label="Last Name"
              onChange={(e) => {
                setLastName(e.target.value);
              }}
              placeholder="Your Last Name"
              value={lastName}
              variant="outlined"
            />
          </Box>
        </Box>
        <Box>
          <Box sx={{ p: 0.5 }}>
            <TextField
              aria-label="Email Address"
              error={emailAddressError}
              fullWidth
              helperText={emailAddressHelperText}
              label="Email Address"
              onChange={(e) => {
                setEmailAddress(e.target.value);
              }}
              placeholder="Your Email Address"
              value={emailAddress}
              variant="outlined"
            />
          </Box>
          <Box sx={{ p: 0.5 }}>
            <TextField
              aria-label="Message"
              fullWidth
              label="Message"
              multiline
              onChange={(e) => {
                setMessage(e.target.value);
              }}
              rows={4}
              placeholder="Your Message"
              value={message}
              variant="outlined"
            />
          </Box>
        </Box>
        <Box sx={{ p: 0.5 }}>
          <Button
            aria-label="Submit Message"
            fullWidth
            onClick={handleClick}
            variant="contained"
            disabled={isEmailSending}
          >
            {isEmailSending ? <CircularProgress color="white" size={25} /> : 'Submit Message'}
          </Button>
        </Box>
      </Box>
      <Snackbar open={isSnackbarVisible} autoHideDuration={6000} onClose={handleSnackbarClose} message={snackbarMessage} action={action} />
    </Box>
  );
};

const ContactFormWithCaptcha = (props) => (
  <GoogleReCaptchaProvider reCaptchaKey="6LexI4IeAAAAAE7hJgzy7u3TP0SAXK9udVJ6f9P0" language="en" useRecaptchaNet={true}>
    <ContactForm {...props} />
  </GoogleReCaptchaProvider>
);

export default ContactFormWithCaptcha;
