import { AppBar, Box, IconButton, Toolbar, Typography } from '@mui/material'
import React from 'react'
import LogoLinesWhite from '../../assets/svg/logo-lines-white.svg'
import LogoCadsoftWhite from '../../assets/svg/logo-cadsoft-white.svg'
import LogoCadsoftPrimaryWhite from '../../assets/svg/logo-cadsoft-primary-white.svg'
import { Launch } from '@mui/icons-material'

const Header = (props) => {
  return (
    <AppBar position="fixed">
      <Toolbar>
        <Box
          component="img"
          src={LogoCadsoftPrimaryWhite} width={140} height={40}
        />
        <Typography color="white" sx={{mx: 2}}>×</Typography>
        <Box
          component="img"
          src={LogoLinesWhite} width={121} height={20}
        />
        <Box sx={{display: "flex", flex: 1,}}></Box>
      </Toolbar>
    </AppBar>
  )
}

export default Header