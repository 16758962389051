import React, { useEffect, useState } from 'react'
import Header from '../layouts/Header'
import { Box, Container, } from '@mui/material';
import BannerVideo from '../modules/BannerVideo'
import ProductsList from '../modules/ProductsList';
import WhatsIncluded from '../modules/WhatsIncluded';
import ModelViewer from '../modules/ModelViewer';
import Statistics from '../modules/Statistics';
import ContactFormWithCaptcha from '../modules/ContactFormWithCaptcha'
import TrustedBy from '../modules/TrustedBy'
import SessionsChart from '../modules/SessionsChart';
import Testimonials from '../modules/Testimonials';
import HowItWorks from '../modules/HowItWorks';

const AppInterface = (props) => {

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    // Define a function to update the state with the current window size
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    // Add the event listener for window resize
    window.addEventListener('resize', handleResize);

    // Call handleResize initially to set the state when the component mounts
    handleResize();

    // Cleanup the event listener on component unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <>
      <Header />
      <Box>
        <BannerVideo windowSize={windowSize} />
      </Box>
      <Box>
        <WhatsIncluded windowSize={windowSize} />
      </Box>
      <Box sx={{py:2,}}>
        <ProductsList windowSize={windowSize} />
      </Box>
      <Box>
        <ModelViewer />
      </Box>
      <Box sx={{display: "flex", justifyContent: "center",}}>
        <Statistics />
      </Box>
      <TrustedBy />
      <SessionsChart />
      <Testimonials />
      <Container maxWidth="xl" id="contact-us">
        <ContactFormWithCaptcha />
      </Container>
      <Box sx={{bgcolor: "#eaeaea", p: 2, textAlign: "center", fontSize: 10, mt:2,}}>© Cadsoft Corporation. All Rights Reserved.</Box>
    </>
  )
}

export default AppInterface