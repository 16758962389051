import { CssBaseline, ThemeProvider } from '@mui/material'
import AppNavigator from './AppNavigator'
import { AppDefaultTheme } from '../themes/AppDefaultTheme'
import '@fontsource/bebas-neue'
import '../config/firebase.config'

const AppWrapper = (props) => {
  if (
    window.location.host === "lines-group-uk.web.app" ||
    window.location.host === "lines-group-uk.firebaseapp.com"
  ) {
    window.location.href = "https://lines.personalarch.com"
  }

  return (
    <ThemeProvider theme={AppDefaultTheme}>
      <AppNavigator />
      <CssBaseline />
    </ThemeProvider>
  )
}

export default AppWrapper