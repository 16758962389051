import React, { useState, useEffect } from "react";
import { Box, Paper, Typography } from "@mui/material";

const models = [
  { name: "Furniture", src: "/assets/glb/5fda2003df16e4003ab24bd9.glb", image: "/assets/glb/5fda2003df16e4003ab24bd9.webp" },
  { name: "Windows", src: "/assets/glb/63cffbd681362100394ae2c4.glb", image: "/assets/glb/63cffbd681362100394ae2c4.webp" },
  { name: "Cabinets", src: "/assets/glb/64a32e31d13fbfc4f0ab1fb7.glb", image: "/assets/glb/64a32e31d13fbfc4f0ab1fb7.webp" },
  { name: "Lighting", src: "/assets/glb/606c70f02216ae003b0f5d46.glb", image: "/assets/glb/606c70f02216ae003b0f5d46.webp" },
  { name: "Plumbing", src: "/assets/glb/6585e852e3c38ddeded71bca.glb", image: "/assets/glb/6585e852e3c38ddeded71bca.webp" },
  { name: "Décor", src: "/assets/glb/605375728c8237003a8f98b8.glb", image: "/assets/glb/605375728c8237003a8f98b8.webp" },
  { name: "Electronics", src: "/assets/glb/5c4b60b660d28d5650657d31.glb", image: "/assets/glb/5c4b60b660d28d5650657d31.webp" },
  { name: "Equipment", src: "/assets/glb/6054a93c8c8237003a8f98e6.glb", image: "/assets/glb/6054a93c8c8237003a8f98e6.webp" },
  { name: "HVAC", src: "/assets/glb/5dcae46598031322449971d5.glb", image: "/assets/glb/5dcae46598031322449971d5.webp" },
];

const ModelViewer = () => {
  const [selectedModel, setSelectedModel] = useState(models[0]);

  useEffect(() => {
    setSelectedModel(models[0]);
  }, []);

  const handleModelChange = (model) => {
    setSelectedModel(model);
  };

  return (
<Box id="models" sx={{ }}>
  {/* Container */}
  <Box
    sx={{
      display: "flex",
      flexDirection: { xs: "column", lg: "row" }, // Column for small screens, row for large screens
      overflow: "hidden",
    }}
  >
    {/* Top Border */}
    <Box sx={{ backgroundColor: "#b71c1c", height: "4px" }} />

    {/* Left Column: Text + Image Grid */}
    <Box
      sx={{
        flex: 1,
        display: "flex",
        flexDirection: "column",
        p: 5,
        gap: 2,
        minWidth: "300px",
        backgroundColor: "#ededed",
      }}
    >
      {/* Text Area */}
      <Box>
        <Typography fontFamily="Bebas Neue" variant="h2" component="h2" sx={{ fontWeight: 600, lineHeight: 0.9 }}>
          Stop losing sales.
        </Typography>
        <Typography variant="h3" component="h3" fontSize={20} sx={{ mb: 2 }}>
          Empower your customers to make confident purchasing decisions and collect usable data along the way.
        </Typography>
        <Typography variant="body1">
          Your products deserve to shine in an environment that does more than display—they need to inspire. By
          showcasing your products in an interactive, engaging space, you give customers the tools they need to feel
          confident about their purchasing decisions. Not only will this drive sales, but you'll gain valuable,
          actionable data to fine-tune your strategy and meet customer needs like never before. Put your products where
          they belong—at the center of the customer experience.
        </Typography>
      </Box>

      {/* Image Grid */}
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fit, minmax(80px, 1fr))",
          gap: 1,
        }}
      >
        {models.map((model, index) => (
          <Paper
            key={index}
            onClick={() => handleModelChange(model)}
            sx={{
              border: selectedModel.name === model.name ? "1px solid #b71c1c" : "1px solid #ccc",
              borderRadius: 1,
              overflow: "hidden",
              cursor: "pointer",
              transition: "all 0.2s ease",
              backgroundColor: "white",
            }}
          >
            <Box
              component="img"
              src={model.image}
              alt={model.name}
              style={{
                width: "100%",
                height: "100px",
                objectFit: "contain",
              }}
            />
            <Typography
              sx={{
                backgroundColor: "white",
                fontSize: "0.7rem",
                textAlign: "center",
                padding: "4px",
              }}
            >
              {model.name}
            </Typography>
          </Paper>
        ))}
      </Box>
    </Box>

    {/* Right Column: 3D Model Viewer */}
    <Box
      sx={{
        flex: 1,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        minHeight: { xs: "300px", lg: "50vh" }, // Ensure a minimum height for small screens
        minWidth: { xs: "100%", lg: "400px" },  // Adjust width based on screen size
        backgroundColor: "#f9f9f9",
        border: "10px solid #b71c1c",
      }}
    >
      {selectedModel && (
        <model-viewer
          ar
          ar-modes="webxr scene-viewer quick-look"
          camera-controls
          camera-orbit="0deg 270deg"
          disable-zoom
          style={{
            width: "100%",
            height: "100%",
            minHeight: "400px",
            maxHeight: "100%", // Limit height
            backgroundImage: "url('/assets/glb/grid-vena.svg')",
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
            backgroundPosition: "center center",
          }}
          tone-mapping="commerce"
          poster={selectedModel.image}
          shadow-intensity="1"
          src={selectedModel.src}
          touch-action="pan-y"
        />
      )}
    </Box>
  </Box>
</Box>

  );
};

export default ModelViewer;
