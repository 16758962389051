import { Box, Container, Typography } from '@mui/material'
import React from 'react'

const WhatsIncluded = (props) => {

  const minWidthSize = props?.windowSize?.width > 768 ? 768 : 360;
  const fontSize = props?.windowSize?.width < 768 ? 14 : 20;
  const headingSize = props?.windowSize?.width < 768 ? 30 : 50;

  return (
    <Box sx={{backgroundColor: "#163c51"}}>
      <Container maxWidth="lg" sx={{p:5,}}>
      <Typography
              color="white"
              component="h1"
              fontFamily="Bebas Neue"
              fontSize={50}
              fontWeight={500}
              lineHeight={1}
              variant="h1"
            >
              What's included.
            </Typography>
        <Box sx={{display: "flex", }}>
          <Typography fontSize={20} color="white" sx={{py: 1,}}>Our collaborative software setup is a seamlessly integrated tech stack that offers an all-in-one feature rich solution with intelligent business analysis at the partner-level with an intuitive visual sales process and comprehensive back-end. This collaborative software suite is designed to boost efficiency and provide you with powerful insights, helping you close sales faster. All of these pieces of software connect seamlessly to create a sales and insights platform that works for your distributor-based business.</Typography>
        </Box>
      </Container>
    </Box>
  )
}

export default WhatsIncluded