import React from 'react';
import { Box, Typography, Grid } from '@mui/material';
import { styled } from '@mui/system';

// Company logo styles with grayscale effect
const LogoWrapper = styled('div')(({ theme }) => ({
  filter: 'grayscale(100%)',
  transition: 'filter 0.1s ease',
  '&:hover': {
    filter: 'grayscale(0%)',
  },
  textAlign: 'center',
  maxWidth: "100px",
  m:1,
}));

// Replace these with your actual SVG or image URLs
const companies = [
  { name: 'Honda', src: '/assets/svg/logo-honda.svg' },
  { name: 'Neighborly', src: '/assets/svg/logo-neighborly.svg' },
  { name: 'Volkswagen', src: '/assets/svg/logo-volkswagen.svg' },
  { name: 'AireServ', src: '/assets/svg/logo-aireserv.svg' },
  { name: 'Lotus', src: '/assets/svg/logo-lotus.svg' },
  { name: 'AGCO', src: '/assets/svg/logo-agco.svg' },
];

const TrustedBy = () => {
  return (
    <Box sx={{ py: 4, textAlign: 'center', backgroundColor: '#f9f9f9' }}>
      <Typography fontFamily="Bebas Neue" lineHeight={.9} color="black" variant="h2" component="h2">
        Trusted By World Class Brands
      </Typography>
      <Typography fontSize={20} sx={{mb:5,}}>To help increase user sessions, engagement, conversions and annual revenue.</Typography>
      <Box sx={{alignItems: "center", display: "flex", flexWrap: "wrap", minWidth: "100px", justifyContent: "space-around",}}>
        {companies.map((company) => (
            <LogoWrapper key={company.name}>
              <Box
                component="img"
                src={company.src}
                alt={`${company.name} logo`}
                sx={{ width: '100px', p: 2.5, }}
              />
            </LogoWrapper>
        ))}
      </Box>
    </Box>
  );
};

export default TrustedBy;
