import { Box, Container, Divider, Paper, Typography } from '@mui/material'
import React from 'react'
import LogoAdvantagePlus from '../../assets/svg/logo-advantage-plus.svg'
import LogoLoop from '../../assets/svg/logo-loop.svg'
import LogoPersonalArchitectWhite from '../../assets/svg/logo-personal-architect-white.svg'
import LogoEditionAI from '../../assets/svg/logo-edition-ai.svg'
import Step1 from '../../assets/webp/step-1.webp'
import Step2 from '../../assets/webp/step-2.webp'
import Step3 from '../../assets/webp/step-3.webp'
import Step4 from '../../assets/webp/step-4.webp'

const ProductsList = (props) => {
  return (
    <Container maxWidth="xl">
      <Box sx={{display: "flex", flexDirection: "column",}}>
        <Box sx={{display: "flex", flexDirection: "row", flexWrap: "wrap", justifyContent: "space-around"}}>
          <Paper sx={{minWidth: 300, maxWidth: 600, my:1,}}>
            <Box>
              <Box sx={{alignItems: "center", backgroundColor: "#8e9f4c", borderTopLeftRadius: 4, borderTopRightRadius: 4, display: "flex", flex: 1, p:2, justifyContent: "center",}}>
                <Box component="img" src={LogoAdvantagePlus} sx={{maxWidth: 400, maxHeight: 60,}} />
              </Box>
              <Box sx={{display: "flex", flexDirection: "column",}}>
                <Typography variant="h6" component="h2" fontWeight={500} lineHeight={1} sx={{p:2,}}>Sales professionals present and build quotes, using their own pricebook.</Typography>
                <Box
                  maxHeight={600}
                  minHeight={320}
                  minWidth={400}
                  maxWidth={"100%"}
                  src={Step1}
                  sx={{
                    backgroundImage: `url(${Step1})`,
                    backgroundPosition: "center center",
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                  }} 
                />
              </Box>
              <Box sx={{ p:2, }}>
                <Typography>Advantage+ is a sales system tailored to your organization that intelligently analyzes revenue streams across your network, ensuring your distribution partners capitalize on conversion opportunities.</Typography>
              </Box>
            </Box>
          </Paper>
          <Paper sx={{minWidth: 300, maxWidth: 600, my:1,}}>
            <Box>
              <Box sx={{alignItems: "center", backgroundColor: "#163c51", borderTopLeftRadius: 4, borderTopRightRadius: 4, display: "flex", flex: 1, p:2, justifyContent: "center",}}>
                <Box component="img" src={LogoLoop} sx={{maxWidth: 400, maxHeight: 60,}} />
              </Box>
              <Box sx={{display: "flex", flexDirection: "column",}}>
                <Typography variant="h6" component="h2" fontWeight={500} lineHeight={1} sx={{p:2,}}>Insights and location-based intelligence to find pain-points in your processes.</Typography>
                <Box
                  maxHeight={600}
                  minHeight={320}
                  minWidth={400}
                  maxWidth={"100%"}
                  src={Step2}
                  sx={{
                    backgroundImage: `url(${Step2})`,
                    backgroundPosition: "center center",
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                  }} 
                />
              </Box>
              <Box sx={{ p:2, }}>
                <Typography>Loop is intuitive business performance management software built for multi-site organizations that provides location-level insights to increase profitability and improve the sales process.</Typography>
              </Box>
            </Box>
          </Paper>
        </Box>
        <Box sx={{display: "flex", flexDirection: "row", flexWrap: "wrap", justifyContent: "space-around"}}>
          <Paper sx={{minWidth: 300, maxWidth: 600, my:1,}}>
            <Box>
              <Box sx={{alignItems: "center", backgroundColor: "#b71c1c", borderTopLeftRadius: 4, borderTopRightRadius: 4, display: "flex", flex: 1, p:2, justifyContent: "center",}}>
                <Box component="img" src={LogoPersonalArchitectWhite} sx={{maxWidth: 400, maxHeight: 60,}} />
              </Box>
              <Box sx={{display: "flex", flexDirection: "column",}}>
                <Typography variant="h6" component="h2" fontWeight={500} lineHeight={1} sx={{p:2,}}>Custom visualizer that enables customers to design with your products.</Typography>
                <Box
                  maxHeight={600}
                  minHeight={320}
                  minWidth={400}
                  maxWidth={"100%"}
                  src={Step3}
                  sx={{
                    backgroundImage: `url(${Step3})`,
                    backgroundPosition: "center center",
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                  }} 
                />
              </Box>
              <Box sx={{ p:2, }}>
                <Typography>Personal Architect is a 3D visualizer and design tool that puts your products in front of customers in an engaging and meaningfully way, improving the sales experience.</Typography>
              </Box>
            </Box>
          </Paper>
          <Paper sx={{minWidth: 300, maxWidth: 600, my:1,}}>
            <Box>
              <Box sx={{alignItems: "center", backgroundColor: "#406c7b", borderTopLeftRadius: 4, borderTopRightRadius: 4, display: "flex", flex: 1, p:2, justifyContent: "center",}}>
                <Box component="img" src={LogoEditionAI} sx={{maxWidth: 400, maxHeight: 60,}} />
              </Box>
              <Box sx={{display: "flex", flexDirection: "column",}}>
                <Typography variant="h6" component="h2" fontWeight={500} lineHeight={1} sx={{p:2,}}>SEO compliant CMS for location based unique content management.</Typography>
                <Box
                  maxHeight={600}
                  minHeight={320}
                  minWidth={400}
                  maxWidth={"100%"}
                  src={Step4}
                  sx={{
                    backgroundImage: `url(${Step4})`,
                    backgroundPosition: "center center",
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                  }} 
                />
              </Box>
              <Box sx={{ p:2, }}>
                <Typography>Edition AI is a unique content management system with SEO compliance for every location in a network of dealers, resellers or franchisees.</Typography>
              </Box>
            </Box>
          </Paper>
        </Box>
      </Box>
    </Container>
  )
}

export default ProductsList